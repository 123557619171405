import * as React from 'react';
import { useMemo } from 'react';

import RichTextInput from 'ra-input-rich-text';
import {
    BooleanInput,
    Create,
    ImageField,
    NumberInput,
    required,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar,
} from 'react-admin'; // eslint-disable-line import/no-unresolved
import { ImageInput } from 'ra-ui-materialui';
import { toolbar } from '../component/QuillToolBar';

const PostCreateToolbar = (props: any) => {
    return (
        <Toolbar {...props}>
            <SaveButton
                label="resources.posts.action.save_and_edit"
                redirect="edit"
                submitOnEnter={true}
            />
            <SaveButton
                label="resources.posts.action.save_and_show"
                redirect="show"
                submitOnEnter={false}
                variant="text"
            />
            {/*<SaveButton
                label="resources.posts.action.save_and_add"
                redirect={false}
                submitOnEnter={false}
                variant="text"
            />*/}
        </Toolbar>
    );
};

const PostCreate = ({ permissions, ...props }: any) => {
    const initialValues = useMemo(
        () => ({
            average_note: 0,
        }),
        []
    );

    const dateDefaultValue = useMemo(() => new Date(), []);

    return (
        <Create {...props}>
            <SimpleForm
                toolbar={<PostCreateToolbar />}
                initialValues={initialValues}
                validate={(values: any) => {
                    const errors: any = {};
                    ['title', 'teaser'].forEach(field => {
                        if (!values[field]) {
                            errors[field] = 'Required field';
                        }
                    });

                    if (values.average_note < 0 || values.average_note > 5) {
                        errors.average_note = 'Should be between 0 and 5';
                    }

                    return errors;
                }}
            >
                <ImageInput source="coverImage" accept="image/*">
                    <ImageField source="base64" title="name" />
                </ImageInput>
                <TextInput autoFocus source="title" />
                <TextInput source="teaser" fullWidth={true} multiline={true} />
                <RichTextInput
                    toolbar={toolbar}
                    source="content"
                    validate={required()}
                />
                {/* <FormSpy subscription={{ values: true }}>
                    {({ values }) =>
                        values.title ? (
                            <NumberInput source="average_note" />
                        ) : null
                    }
                </FormSpy>*/}
                {/*
                <DateInput
                    source="published_at"
                    defaultValue={dateDefaultValue}
                />*/}
                <BooleanInput source="active" defaultValue={false} />
                <NumberInput source="displayOrder" min={1}></NumberInput>
                {/*<ArrayInput
                    source="backlinks"
                    defaultValue={backlinksDefaultValue}
                    validate={[required()]}
                >
                    <SimpleFormIterator>
                        <DateInput source="date" />
                        <TextInput source="url" />
                    </SimpleFormIterator>
                </ArrayInput>*/}
            </SimpleForm>
        </Create>
    );
};

export default PostCreate;
