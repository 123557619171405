import { People } from '@material-ui/icons';
import ArtistCreate from './ArtistCreate';
import ArtistList from './ArtistList';
import ArtistEdit from './ArtistEdit';

export default {
    list: ArtistList,
    create: ArtistCreate,
    edit: ArtistEdit,
    icon: People,
};
