import { EmailField, List } from 'ra-ui-materialui';
import React, { cloneElement, FC } from 'react';
import { Datagrid, DateField, TextField } from 'react-admin';

const MessageList = props => (
    <List {...props}>
        <Datagrid rowClick="show">
            <TextField source="id" />
            <EmailField source="email" />
            <TextField source="content" />
            <DateField label="Send Date" source="createdDate" />
        </Datagrid>
    </List>
);
export default MessageList;
