import * as React from 'react';
import { FC } from 'react';
import {
    Create,
    NumberInput,
    TextInput,
    required,
    CreateProps,
    BooleanInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { ImageField, ImageInput, SimpleForm } from 'ra-ui-materialui';

export const styles = {
    price: { width: '7em' },
    width: { width: '7em' },
    height: { width: '7em' },
    stock: { width: '7em' },
    widthFormGroup: { display: 'inline-block' },
    heightFormGroup: { display: 'inline-block', marginLeft: 32 },
};

const useStyles = makeStyles(styles);

const ProjectCreate: FC<CreateProps> = props => {
    const classes = useStyles();
    return (
        <Create {...props}>
            <SimpleForm>
                <ImageInput source="coverImage">
                    <ImageField source="base64" title="name"></ImageField>
                </ImageInput>
                <TextInput
                    title="Project Name"
                    source="name"
                    validate={required()}
                />
                <TextInput source="artistName" validate={required()} />
                <TextInput source="url" validate={required()} />
                <BooleanInput source="active" defaultValue={false} />
                <NumberInput source="displayOrder" min={0}></NumberInput>
            </SimpleForm>
        </Create>
    );
};

export default ProjectCreate;
