import React, { FC } from 'react';

import Welcome from './Welcome';

const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '0.5em' },
    rightCol: { flex: 1, marginLeft: '0.5em' },
    singleCol: { marginTop: '1em', marginBottom: '1em' },
};

const Dashboard: FC = () => {
    return (
        <div>
            <div style={styles.flex} />
        </div>
    );
};

export default Dashboard;
