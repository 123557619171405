import * as React from 'react';
import { Box, Button, Card, IconButton } from '@material-ui/core';
import { FormatColorReset } from '@material-ui/icons';
import { showNotification } from 'react-admin';
import { fetchUtils } from 'ra-core';
import { put } from 'redux-saga/effects';
import { connect } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';

const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '0.5em' },
    rightCol: { flex: 1, marginLeft: '0.5em' },
    singleCol: { marginTop: '1em', marginBottom: '1em' },
};

const Configuration = connect(undefined, { showNotification })((props: any) => {
    const callRedeployLanding = () => {
        const token = JSON.parse(localStorage.getItem('jwt') as string);
        let options = {
            method: 'POST',
            headers: new Headers({
                Authorization: ' Bearer ' + token,
            }),
        };

        let url =
            process.env.REACT_APP_ADMIN_API_ENDPOINT +
            '/management/configurations/landing-page/redeploy';
        fetchUtils
            .fetchJson(url, options)
            .then(() => {
                props.showNotification('Success');
            })
            .catch(e => {
                props.showNotification(e.message, 'warning');
            });
    };

    return (
        <Box m="auto">
            <Button
                variant="outlined"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={callRedeployLanding}
            >
                Deploy Changes
            </Button>
        </Box>
    );
});

export default Configuration;
