import { httpClient } from './dataProvider';
import { stringify } from 'querystring';

const simpleRest = {
    get: (resource, params) => {
        return httpClient(
            process.env.REACT_APP_ADMIN_API_ENDPOINT +
                `/${resource}?${stringify(params)}`,
            {
                method: 'GET',
            }
        );
    },
    getList: (resource, params) => {
        return httpClient(
            process.env.REACT_APP_ADMIN_API_ENDPOINT +
                `/${resource}?${stringify(params)}`,
            {
                method: 'GET',
            }
        );
    },
    patch(resource: string, params: {}) {
        return httpClient(
            process.env.REACT_APP_ADMIN_API_ENDPOINT + `/${resource}`,
            {
                method: 'PATCH',
                body: JSON.stringify(params),
            }
        );
    },
    post(resource: string, params: {}) {
        return httpClient(
            process.env.REACT_APP_ADMIN_API_ENDPOINT + `/${resource}`,
            {
                method: 'POST',
                body: JSON.stringify(params),
            }
        );
    },
    upload(resource: string, file: File) {
        const formData = new FormData();
        formData.append('file', file, file.name);
        const headers = new Headers({
            // 'content-type': 'multipart/form-data',
        });
        return httpClient(
            process.env.REACT_APP_ADMIN_API_ENDPOINT + `/${resource}`,
            {
                method: 'POST',
                headers: headers,
                body: formData,
            }
        );
    },
};

export default simpleRest;
