import * as React from 'react';
import { memo } from 'react';
import { Button, Menu, MenuItem, Tooltip, IconButton, useMediaQuery, } from '@material-ui/core';
import SortIcon from '@material-ui/icons/Sort';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { shallowEqual } from 'react-redux';
import { useListSortContext, useTranslate, getFieldLabelTranslationArgs, } from 'ra-core';
/**
 * A button allowing to change the sort field and order.
 *
 * To be used inside a ListContext (e.g. inside a <List> or <ReferenceManyField>)
 *
 * Expects one 'fields' prop, containing an array of field strings that shall
 * be used and displayed for sorting.
 *
 * When users clicks on the <SortButton>, they see a dropdown list with the
 * proposed sorting fields. Once they click on one of these fields, the related
 * list refreshes, re-sorted.
 *
 * @example
 *
 * import * as React from 'react';
 * import { TopToolbar, SortButton, CreateButton, ExportButton } from 'react-admin';
 *
 * const ListActions: FC = props => (
 *     <TopToolbar>
 *         <SortButton fields={['reference', 'sales', 'stock']} />
 *         <CreateButton basePath={props.basePath} />
 *         <ExportButton />
 *     </TopToolbar>
 * );
 */
var SortButton = function (_a) {
    var fields = _a.fields, _b = _a.label, label = _b === void 0 ? 'ra.sort.sort_by' : _b, _c = _a.icon, icon = _c === void 0 ? defaultIcon : _c;
    var _d = useListSortContext(), resource = _d.resource, currentSort = _d.currentSort, setSort = _d.setSort;
    var translate = useTranslate();
    var isXSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('xs');
    });
    var _e = React.useState(null), anchorEl = _e[0], setAnchorEl = _e[1];
    var handleClick = function (event) {
        setAnchorEl(event.currentTarget);
    };
    var handleClose = function () {
        setAnchorEl(null);
    };
    var handleChangeSort = function (event) {
        var field = event.currentTarget.dataset.sort;
        setSort(field, field === currentSort.field
            ? inverseOrder(currentSort.order)
            : 'ASC');
        setAnchorEl(null);
    };
    var buttonLabel = translate(label, {
        field: translate.apply(void 0, getFieldLabelTranslationArgs({
            resource: resource,
            source: currentSort.field,
        })),
        order: translate("ra.sort." + currentSort.order),
        _: label,
    });
    return (React.createElement(React.Fragment, null,
        isXSmall ? (React.createElement(Tooltip, { title: buttonLabel },
            React.createElement(IconButton, { "aria-label": buttonLabel, color: "primary", onClick: handleClick }, icon))) : (React.createElement(Button, { "aria-controls": "simple-menu", "aria-haspopup": "true", color: "primary", onClick: handleClick, startIcon: icon, endIcon: React.createElement(ArrowDropDownIcon, null), size: "small" }, buttonLabel)),
        React.createElement(Menu, { id: "simple-menu", anchorEl: anchorEl, keepMounted: true, open: Boolean(anchorEl), onClose: handleClose }, fields.map(function (field) { return (React.createElement(MenuItem, { onClick: handleChangeSort, "data-sort": field, key: field },
            translate.apply(void 0, getFieldLabelTranslationArgs({
                resource: resource,
                source: field,
            })),
            ' ',
            translate("ra.sort." + (currentSort.field === field
                ? inverseOrder(currentSort.order)
                : 'ASC')))); }))));
};
var defaultIcon = React.createElement(SortIcon, null);
var inverseOrder = function (sort) { return (sort === 'ASC' ? 'DESC' : 'ASC'); };
var arePropsEqual = function (prevProps, nextProps) {
    return shallowEqual(prevProps.fields, nextProps.fields);
};
export default memo(SortButton, arePropsEqual);
