import * as React from 'react';
import { Chip, useMediaQuery, Button, Card } from '@material-ui/core';
import {
    BooleanField,
    Datagrid,
    DateField,
    downloadCSV,
    EditButton,
    Filter,
    List,
    NumberField,
    NumberInput,
    ReferenceArrayField,
    SearchInput,
    ShowButton,
    SimpleList,
    SingleFieldList,
    TextField,
    TextInput,
    useTranslate,
} from 'react-admin'; // eslint-disable-line import/no-unresolved

import {
    useListContext,
    TopToolbar,
    CreateButton,
    ExportButton,
    sanitizeListRestProps,
} from 'react-admin';
import { Publish } from '@material-ui/icons';
import { useState } from 'react';
import simpleRest from '../simpleRest';
import { useNotify, useRefresh } from 'ra-core/lib';
const ListActions = props => {
    const { empty, className, exporter, filters, maxResults, ...rest } = props;
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        hasCreate,
        basePath,
        selectedIds,
        showFilter,
        total,
    } = useListContext();

    const notify = useNotify();
    const refresh = useRefresh();

    const onUpload = (file: File) => {
        simpleRest
            .upload('payments/import', file)
            .then(() => {
                notify('Imported successfully');
                refresh();
            })
            .catch(rs => {
                notify('Failed to import. ' + rs.body.title);
            });
    };

    const importButton = (
        <div
            style={{
                height: '500px',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
            }}
        >
            <Card
                style={{
                    width: '200px',
                    height: '150px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: 'auto',
                }}
            >
                <Button
                    style={{ margin: 'auto', height: '100%', width: '100%' }}
                    component="label"
                >
                    <Publish></Publish>
                    Import
                    <input
                        type="file"
                        hidden
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={e => onUpload(e.target.files[0])}
                        onClick={event => {
                            event.target['value'] = null;
                        }}
                    />
                </Button>
            </Card>
        </div>
    );
    return empty ? (
        importButton
    ) : (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {/*{filters &&
                cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button',
                })}
            <CreateButton basePath={basePath} />
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filterValues={filterValues}
                maxResults={maxResults}
            />*/}
            <Button variant="contained" component="label">
                <Publish></Publish>
                Import
                <input
                    type="file"
                    hidden
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={e => onUpload(e.target.files[0])}
                    onClick={event => {
                        event.target['value'] = null;
                    }}
                />
            </Button>
        </TopToolbar>
    );
};

const PaymentList = props => (
    <List
        empty={<ListActions empty={true} {...props}></ListActions>}
        actions={<ListActions {...props}></ListActions>}
        {...props}
    >
        <Datagrid rowClick="edit">
            {/*<TextField source="id" />*/}
            <TextField source="contractNumber" />
            <TextField source="companyName" />
            <TextField source="bankNumber" />
            <TextField source="bankName" />
            <TextField source="bankBrand" />
            <TextField source="bankReceiver" />
            <NumberField source="payment" />
            <TextField source="currency" />
            <TextField source="transferText" />
            <BooleanField source="legalChecked" />
            <DateField source="paymentDate" />
            <TextField source="note" />
            <TextField source="approval" />
            <TextField source="receipt" />
            <DateField source="createdDate" />
        </Datagrid>
    </List>
);

export default PaymentList;
