import RichTextInput from 'ra-input-rich-text';
import * as React from 'react';
import {
    CloneButton,
    Edit,
    FormTab,
    ImageField,
    ImageInput,
    NumberInput,
    required,
    ShowButton,
    TabbedForm,
    TextInput,
    TopToolbar,
} from 'react-admin'; // eslint-disable-line import/no-unresolved
import { Box } from '@material-ui/core';

import PostTitle from './PostTitle';
import { BooleanInput } from 'ra-ui-materialui';
import { toolbar } from '../component/QuillToolBar';

const EditActions = ({ basePath, data, hasShow }: any) => (
    <TopToolbar>
        <CloneButton
            className="button-clone"
            basePath={basePath}
            record={data}
        />
        {hasShow && <ShowButton basePath={basePath} record={data} />}
    </TopToolbar>
);

const SanitizedBox = ({ fullWidth, basePath, ...props }: any) => (
    <Box {...props} />
);

const PostEdit = ({ permissions, ...props }: any) => (
    <Edit title={<PostTitle />} actions={<EditActions />} {...props}>
        <TabbedForm initialValues={{ average_note: 0 }} warnWhenUnsavedChanges>
            <FormTab label="resources.posts.form.summary">
                <SanitizedBox
                    display="flex"
                    flexDirection="column"
                    width="100%"
                    justifyContent="space-between"
                    fullWidth
                >
                    <TextInput disabled source="id" />
                    <TextInput
                        source="title"
                        validate={required()}
                        resettable
                    />
                </SanitizedBox>
                <TextInput
                    multiline={true}
                    fullWidth={true}
                    source="teaser"
                    validate={required()}
                    resettable
                />
                <BooleanInput source="active" label="Active" />
                <NumberInput source="displayOrder" min={1}></NumberInput>
            </FormTab>
            <FormTab label="resources.posts.form.body">
                <ImageInput source="coverImage" accept="image/*">
                    <ImageField source="base64" title="name" />
                </ImageInput>
                <RichTextInput
                    toolbar={toolbar}
                    source="content"
                    label=""
                    validate={required()}
                    addLabel={false}
                />
            </FormTab>
        </TabbedForm>
    </Edit>
);

export default PostEdit;
