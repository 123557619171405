import {
    DateField,
    EmailField,
    Show,
    SimpleShowLayout,
    TextField,
} from 'ra-ui-materialui';
import React from 'react';

export const MessageShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <EmailField source="email" />
            <TextField source="content" />
            <DateField source="createdDate" />
        </SimpleShowLayout>
    </Show>
);
