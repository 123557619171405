"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateContextProvider = void 0;
var React = __importStar(require("react"));
var RecordContext_1 = require("../RecordContext");
var CreateContext_1 = require("./CreateContext");
var SaveContext_1 = require("./SaveContext");
// @ts-ignore
// @ts-ignore
/**
 * Create a Create Context.
 *
 * @example
 *
 * const MyCreate = (props) => {
 *     const controllerProps = useCreateController(props);
 *     return (
 *         <CreateContextProvider value={controllerProps}>
 *             <MyCreateView>
 *         </CreateContextProvider>
 *     );
 * };
 *
 * const MyCreateView = () => {
 *     const { record } = useRecordContext();
 *     // or, to rerender only when the save operation change but not data
 *     const { saving } = useCreateContext();
 * }
 *
 * @see CreateContext
 * @see RecordContext
 */
exports.CreateContextProvider = function (_a) {
    var children = _a.children, value = _a.value;
    return (React.createElement(CreateContext_1.CreateContext.Provider, { value: value },
        React.createElement(SaveContext_1.SaveContextProvider, { value: SaveContext_1.usePickSaveContext(value) },
            React.createElement(RecordContext_1.RecordContextProvider, { value: RecordContext_1.usePickRecordContext(value) }, children))));
};
