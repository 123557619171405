import {
    Button,
    EmailField,
    List,
    SortButton,
    TopToolbar,
    CreateButton,
    Confirm,
    FilterButton,
    BooleanInput,
    TextInput,
} from 'ra-ui-materialui';
import React, { cloneElement, FC, useState } from 'react';
import {
    Datagrid,
    DateField,
    Filter,
    NumberField,
    showNotification,
    TextField,
} from 'react-admin';
import { Create, Notifications } from '@material-ui/icons';
import {
    fetchUtils,
    sanitizeListRestProps,
    useListContext,
    useRefresh,
} from 'ra-core';
import { connect } from 'react-redux';
import { translate, useNotify } from 'ra-core/lib';
import { CircularProgress } from '@material-ui/core';

const ListActions: FC<any> = (props: any) => {
    const { showNotification, refresh, filters, className, ...rest } = props;
    const [loading, setLoading] = useState<boolean>();
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        hasCreate,
        basePath,
        selectedIds,
        showFilter,
        total,
    } = useListContext();
    const notify = useNotify();
    let onGenerateAccounts = () => {
        setLoading(true);
        if (
            !window.confirm(
                'Continue to generate missing account for partners?'
            )
        ) {
            return;
        }
        const token = JSON.parse(localStorage.getItem('jwt') as string);
        let options = {
            method: 'POST',
            headers: new Headers({
                Authorization: ' Bearer ' + token,
            }),
        };
        let url =
            process.env.REACT_APP_ADMIN_API_ENDPOINT +
            '/clidashboard/users/generate';
        fetchUtils
            .fetchJson(url, options)
            .then(rs => {
                setLoading(false);
                notify(rs.body + ' accounts are generated', 'info');
                refresh();
            })
            .catch(e => {
                setLoading(false);
                notify(e.message, 'error');
            });
    };

    let onSendDataUpdatedNotifications = () => {
        setLoading(true);
        if (
            !window.confirm(
                'Continue to send data updated notifications to partners?'
            )
        ) {
            return;
        }
        const token = JSON.parse(localStorage.getItem('jwt') as string);
        let options = {
            method: 'POST',
            headers: new Headers({
                Authorization: ' Bearer ' + token,
            }),
        };
        let url =
            process.env.REACT_APP_ADMIN_API_ENDPOINT +
            '/clidashboard/users/send-data-updated-notifications';
        fetchUtils
            .fetchJson(url, options)
            .then(rs => {
                setLoading(false);
                notify(rs.body + ' notifications sent', 'info');
                refresh();
            })
            .catch(e => {
                setLoading(false);
                notify(e.message, 'error');
            });
    };

    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters &&
                cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button',
                })}
            <Button
                color="primary"
                label="Generate accounts"
                onClick={onGenerateAccounts}
                disabled={loading}
            >
                {loading ? (
                    <CircularProgress size={25} thickness={2} />
                ) : (
                    <Create></Create>
                )}
            </Button>
            <Button
                color="primary"
                label="Send Data Notifications"
                onClick={onSendDataUpdatedNotifications}
                disabled={loading}
            >
                {loading ? (
                    <CircularProgress size={25} thickness={2} />
                ) : (
                    <Notifications></Notifications>
                )}
            </Button>
            {/*<ExportButton />*/}
        </TopToolbar>
    );
};

const PartnerFilter = (props: any) => (
    <Filter {...props}>
        <TextInput label="Email Contains" source="email.contains"></TextInput>
        <TextInput
            label="Contract Number Equals"
            source="contractNumber.equals"
        ></TextInput>
        <TextInput
            label="Contract Number Contains"
            source="contractNumber.contains"
        ></TextInput>
    </Filter>
);

export const PartnerList = (props: any) => {
    const refresh = useRefresh();

    return (
        <List
            {...props}
            filters={<PartnerFilter />}
            actions={
                <ListActions
                    refresh={refresh}
                    showNotification={props.showNotification}
                    {...props}
                />
            }
        >
            <Datagrid rowClick="show">
                {/*<TextField source="id" />*/}
                <TextField source="contractNumber" />
                <TextField source="address" />
                <TextField source="bankBrand" />
                <TextField source="bankName" />
                <TextField source="bankNumber" />
                <TextField source="bankReceiver" />
                <TextField source="companyName" />
                <TextField source="contractSigner" />
                <EmailField source="email" />
                <DateField source="expiredDate" />
                <TextField source="phone" />
                <NumberField source="sharePercentage" />
                <DateField source="signedDate" />
                <TextField source="taxNumber" />
                <NumberField source="taxPercentage" />
                {/*<TextField source="userId" />*/}
                <TextField source="userLogin" />
            </Datagrid>
        </List>
    );
};
