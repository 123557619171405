import * as React from 'react';
import { FC } from 'react';
import { Box, Chip, useMediaQuery, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
    CreateButton,
    ExportButton,
    Filter,
    FilterProps,
    InputProps,
    ListBase,
    ListProps,
    NumberInput,
    Pagination,
    ReferenceInput,
    SearchInput,
    SelectInput,
    SortButton,
    Title,
    TopToolbar,
    useListContext,
    useTranslate,
} from 'react-admin';

import GridList from './GridList';
import Aside from './Aside';
import { BooleanInput } from 'ra-ui-materialui';

const useQuickFilterStyles = makeStyles(theme => ({
    root: {
        marginBottom: theme.spacing(3),
    },
}));

export const ProjectFilter: FC<Omit<FilterProps, 'children'>> = props => (
    <Filter {...props}>
        {/*<SearchInput source="q" alwaysOn />*/}
        <BooleanInput source="active.equals"></BooleanInput>
    </Filter>
);

const ListActions: FC<any> = ({ isSmall }) => (
    <TopToolbar>
        {isSmall && <ProjectFilter context="button" />}
        <SortButton
            fields={[
                'active',
                'displayOrder',
                'lastModifiedDate',
                'createdDate',
            ]}
        />
        <CreateButton basePath="/artists" />
        <ExportButton />
    </TopToolbar>
);

const ArtistList: FC<ListProps> = props => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
    return (
        <ListBase
            perPage={20}
            sort={{ field: 'lastModifiedDate', order: 'DESC' }}
            {...props}
        >
            <ProjectListView isSmall={isSmall} />
        </ListBase>
    );
};

const ProjectListView: FC<{ isSmall: boolean }> = ({ isSmall }) => {
    const { defaultTitle } = useListContext();
    return (
        <>
            <Title defaultTitle={defaultTitle} />
            <ListActions isSmall={isSmall} />
            {isSmall && (
                <Box m={1}>
                    <ProjectFilter context="form" />
                </Box>
            )}
            <Box display="flex">
                <Aside />
                <Box width={isSmall ? 'auto' : 'calc(100% - 16em)'}>
                    <GridList />
                    <Pagination rowsPerPageOptions={[10, 20, 40]} />
                </Box>
            </Box>
        </>
    );
};
export default ArtistList;
