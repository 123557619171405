import { fetchUtils } from 'ra-core';
import simpleRestProvider from './restDataProvider';
import addUploadCapabilities from './addUploadFeature';
import { DataProvider } from 'react-admin';

export const httpClient = (url: string, options: any = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = JSON.parse(localStorage.getItem('jwt') as string);
    options.headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
};

let simpleRestDataProvider = simpleRestProvider(
    process.env.REACT_APP_ADMIN_API_ENDPOINT,
    httpClient
);
const dataProvider = addUploadCapabilities(
    simpleRestDataProvider
) as DataProvider;

export default dataProvider;
