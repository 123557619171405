import * as React from 'react';
import { useEffect } from 'react';
import { Admin, Resource, DataProvider } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';

import authProvider from './authProvider';
import themeReducer from './themeReducer';
import { Login, Layout } from './layout';
import { Dashboard } from './dashboard';
import customRoutes from './routes';
import englishMessages from './i18n/en';
import projects from './projects';
import posts from './posts';
import artists from './artists';
import partners from './partners';
import dataProvider from './dataProvider';
import payments from './payments';
import messages from './messages';
require('dotenv').config();

const i18nProvider = polyglotI18nProvider(locale => {
    if (locale === 'fr') {
        return import('./i18n/fr').then(messages => messages.default);
    }

    // Always fallback on english
    return englishMessages;
}, 'en');

interface AppProps {
    onUnmount: () => void;
    dataProvider: DataProvider;
}

const App = () => {
    return (
        <Admin
            title="Admin"
            dataProvider={dataProvider}
            customReducers={{ theme: themeReducer }}
            customRoutes={customRoutes}
            authProvider={authProvider}
            dashboard={Dashboard}
            loginPage={Login}
            layout={Layout}
            i18nProvider={i18nProvider}
            disableTelemetry
        >
            <Resource name="projects" {...projects} />
            <Resource name="blogs" {...posts} />
            <Resource name="artists" {...artists} />
            <Resource name="messages" {...messages} />
            <Resource name="partners" {...partners} />
            <Resource name="payments" {...payments} />
        </Admin>
    );
};

export default App;
