import { TranslationMessages } from 'react-admin';
import englishMessages from 'ra-language-english';

const customEnglishMessages: TranslationMessages = {
    ...englishMessages,
    pos: {
        search: 'Search',
        configuration: 'Configuration',
        language: 'Language',
        theme: {
            name: 'Theme',
            light: 'Light',
            dark: 'Dark',
        },
        dashboard: {
            monthly_revenue: 'Monthly Revenue',
            month_history: '30 Day Revenue History',
            new_orders: 'New Orders',
            pending_reviews: 'Pending Reviews',
            all_reviews: 'See all reviews',
            new_customers: 'New Customers',
            all_customers: 'See all customers',
            pending_orders: 'Pending Orders',
            order: {
                items:
                    'by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items',
            },
            welcome: {
                title: 'Welcome to the react-admin e-commerce dashboard',
                subtitle:
                    "This is the admin of an imaginary poster shop. Feel free to explore and modify the data - it's local to your computer, and will reset each time you reload.",
                ra_button: 'react-admin site',
                demo_button: 'Source for this dashboard',
            },
        },
        menu: {
            landingPage: 'Landing Page',
            posts: 'Posts',
            projects: 'Projects',
            artists: 'Artists',
            messages: 'Messages',
            configuration: 'Configuration',
            clidashboard: 'Partner Board',
        },
    },
    resources: {
        projects: {
            name: 'Project |||| Projects',
            filters: {
                active: {
                    name: 'Active',
                    on: 'On',
                    off: 'Off',
                },
            },
            fields: {
                projects: 'Projects',
                name: 'Name',
            },
        },
        posts: {
            name: 'Post |||| Posts',
            list: {
                search: 'Search',
            },
            form: {
                summary: 'Summary',
                body: 'Body',
                miscellaneous: 'Miscellaneous',
                comments: 'Comments',
            },
            edit: {
                title: 'Post "%{title}"',
            },
            action: {
                save_and_edit: 'Save and Edit',
                save_and_add: 'Save and Add',
                save_and_show: 'Save and Show',
                save_with_average_note: 'Save with Note',
            },
        },
        artists: {
            name: 'Artist |||| Artists',
        },
        partners: {
            name: 'Partner |||| Partners',
        },
        payments: {
            name: 'Payment |||| Payments',
        },
    },
    error: {
        http: {
            '500': 'Lỗi server',
        },
    },
};

export default customEnglishMessages;
