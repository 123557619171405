import * as React from 'react';
import { FC } from 'react';
import {
    BooleanInput,
    Datagrid,
    DateField,
    Edit,
    EditButton,
    EditProps,
    FormTab,
    NumberInput,
    Pagination,
    ReferenceInput,
    ReferenceManyField,
    required,
    SelectInput,
    TabbedForm,
    TextField,
    TextInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import { styles as createStyles } from './ArtistCreate';
import { Project } from '../types';
import { ImageField, ImageInput, SimpleForm } from 'ra-ui-materialui';

interface ProjectTitleProps {
    record?: Project;
}

const ProjectTitle: FC<ProjectTitleProps> = ({ record }) =>
    record ? <span>Poster #{record.reference}</span> : null;

const useStyles = makeStyles({
    ...createStyles,
    comment: {
        maxWidth: '20em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    tab: {
        maxWidth: '40em',
        display: 'block',
    },
});

const ArtistEdit: FC<EditProps> = props => {
    const classes = useStyles();
    return (
        <Edit {...props} title={<ProjectTitle />}>
            <SimpleForm>
                <ImageInput source="coverImage">
                    <ImageField source="base64" title="name"></ImageField>
                </ImageInput>
                <TextInput
                    title="Artist Name"
                    source="name"
                    validate={required()}
                />
                <BooleanInput source="active" defaultValue={false} />
                <NumberInput source="displayOrder" min={0}></NumberInput>
            </SimpleForm>
        </Edit>
    );
};

const requiredValidate = [required()];

export default ArtistEdit;
