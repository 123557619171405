import { EditGuesser, ListGuesser, ShowGuesser } from 'ra-ui-materialui';
import { People } from '@material-ui/icons';
import { PartnerList } from './PartnerList';
import { PartnerShow } from './PartnerShow';

export default {
    list: PartnerList,
    show: PartnerShow,
    icon: People,
};
