import Album from '@material-ui/icons/Album';
import ProjectList from './ProjectList';
import ProjectEdit from './ProjectEdit';
import ProjectCreate from './ProjectCreate';

export default {
    list: ProjectList,
    create: ProjectCreate,
    edit: ProjectEdit,
    icon: Album,
};
