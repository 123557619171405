import * as React from 'react';
import { Route } from 'react-router-dom';
import Configuration from './configuration/Configuration';
import LandingConfiguration from './landing-page/Configuration';

export default [
    <Route exact path="/configuration" render={() => <Configuration />} />,
    <Route
        exact
        path="/landing-page/configuration"
        render={() => <LandingConfiguration />}
    />,
];
