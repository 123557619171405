import {
    CreateButton,
    DateField,
    EmailField,
    ExportButton,
    NumberField,
    Show,
    SimpleShowLayout,
    SortButton,
    TextField,
    TopToolbar,
} from 'ra-ui-materialui';
import React, { FC } from 'react';
import { IconButton } from '@material-ui/core';

export const PartnerShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="address" />
            <TextField source="bankBrand" />
            <TextField source="bankName" />
            <TextField source="bankNumber" />
            <TextField source="bankReceiver" />
            <TextField source="companyName" />
            <TextField source="contractNumber" />
            <TextField source="contractSigner" />
            <EmailField source="email" />
            <DateField source="expiredDate" />
            <TextField source="phone" />
            <NumberField source="sharePercentage" />
            <DateField source="signedDate" />
            <TextField source="taxNumber" />
            <NumberField source="taxPercentage" />
            <TextField source="userId" />
            <TextField source="userLogin" />
        </SimpleShowLayout>
    </Show>
);
