import * as React from 'react';
import {
    RichTextField,
    ShowContextProvider,
    ShowView,
    Tab,
    TabbedShowLayout,
    TextField,
    UrlField,
    useShowController,
    useLocale,
    ImageField,
} from 'react-admin';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import PostTitle from './PostTitle';
import {
    BooleanField,
    DateField,
    ImageInput,
    NumberField,
} from 'ra-ui-materialui';

const CreateRelatedComment = ({ record }: any) => (
    <Button
        component={Link}
        to={{
            pathname: '/comments/create',
            state: { record: { post_id: record.id } },
        }}
    >
        Add comment
    </Button>
);

const PostShow = (props: any) => {
    const controllerProps = useShowController(props);
    const locale = useLocale();
    return (
        <ShowContextProvider value={controllerProps}>
            <ShowView
                title={<PostTitle />}
                defaultTitle={'Not found'}
                loaded
                loading
                version={1}
            >
                <TabbedShowLayout>
                    <Tab label="resources.posts.form.summary">
                        <TextField source="id" />
                        <TextField source="title" />
                        <TextField source="teaser" />
                        <BooleanField source="active" />
                        <TextField source="slug" />
                        <NumberField source="displayOrder" />
                        <DateField source="createdDate" />
                        <DateField source="lastModifiedDate" />
                    </Tab>
                    <Tab label="resources.posts.form.body">
                        <ImageField source="coverImage.base64" label="" />
                        <RichTextField
                            source="content"
                            stripTags={false}
                            label="Content"
                            addLabel={false}
                        />
                    </Tab>
                </TabbedShowLayout>
            </ShowView>
        </ShowContextProvider>
    );
};

export default PostShow;
