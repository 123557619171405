import * as React from 'react';
import { FC } from 'react';
import { Card, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import {
    FilterList,
    FilterListItem,
    FilterLiveSearch,
    useGetList,
} from 'react-admin';

const useStyles = makeStyles(theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            width: '15em',
            marginRight: '1em',
            overflow: 'initial',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}));

const Aside: FC = () => {
    const classes = useStyles();
    return (
        <Card className={classes.root}>
            <CardContent>
                {/*<FilterLiveSearch />*/}

                <FilterList
                    label="resources.projects.filters.active.name"
                    icon={<AttachMoneyIcon />}
                >
                    <FilterListItem
                        label="resources.projects.filters.active.on"
                        value={{
                            active: {
                                equals: true,
                            },
                        }}
                    />
                    <FilterListItem
                        label="resources.projects.filters.active.off"
                        value={{
                            active: {
                                equals: false,
                            },
                        }}
                    />
                </FilterList>
            </CardContent>
        </Card>
    );
};

export default Aside;
