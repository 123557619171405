import { DataProvider } from 'ra-core';
/**
 * For posts update only, convert uploaded image in base 64 and attach it to
 * the `picture` sent property, with `src` and `title` attributes.
 */

const resources = ['blogs', 'projects', 'artists'];

const convertFileToBase64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.rawFile);

        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

const addUploadCapabilities = (dataProvider: DataProvider) => ({
    ...dataProvider,
    update: (resource: string, params: any) => {
        let data = params.data;
        if (resources.indexOf(resource) < 0) {
            // fallback to the default implementation
            return dataProvider.update(resource, params);
        }
        // Freshly dropped pictures are File objects
        // and must be converted to base64 strings
        let properties: any[] = Object.keys(data);

        for (const property of properties) {
            let value = data[property];

            if (value && value.rawFile && value.rawFile instanceof File) {
                return convertFileToBase64(value).then(base64 => {
                    data[property] = { base64, name: value.rawFile.name };
                    return dataProvider.update(resource, params);
                });
            }
        }

        return dataProvider.update(resource, params);
    },
    create: (resource: string, params: any) => {
        let data = params.data;
        if (resources.indexOf(resource) < 0) {
            // fallback to the default implementation
            return dataProvider.create(resource, params);
        }
        // Freshly dropped pictures are File objects
        // and must be converted to base64 strings
        let properties: any[] = Object.keys(data);

        for (const property of properties) {
            let value = data[property];

            if (value.rawFile && value.rawFile instanceof File) {
                return convertFileToBase64(value).then(base64 => {
                    data[property] = { base64, name: value.rawFile.name };
                    return dataProvider.create(resource, params);
                });
            }
        }

        return dataProvider.create(resource, params);
    },
});

export default addUploadCapabilities;
